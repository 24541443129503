.dropdown-check-list {
    display: flex;
    flex-direction: column;
    justify-content: center;
    width: max-content;
}

.dropdown-check-list .anchor {
    position: relative;
    cursor: pointer;
    display: inline-block;
    padding: 5px 50px 5px 10px;
    border: 1px solid #ccc;
    border-radius: 3px;
}

.dropdown-check-list .anchor:after {
    position: absolute;
    content: "";
    border-left: 1px solid black;
    border-top: 1px solid black;
    padding: 5px;
    right: 10px;
    top: 25%;
    -moz-transform: rotate(-135deg);
    -ms-transform: rotate(-135deg);
    -o-transform: rotate(-135deg);
    -webkit-transform: rotate(-135deg);
    transform: rotate(-135deg);
}

.dropdown-check-list .anchor:active:after {
    /* right: 8px; */
    top: 21%;
}

.dropdown-check-list ul.items {
    padding: 2px;
    display: none;
    margin: 0;
    border: 1px solid #ccc;
    border-top: none;
    max-height: 220px;
    overflow-y: scroll;
}

.dropdown-check-list ul.items li {
    list-style: none;
    font-size: 1.1em;
}

.dropdown-check-list.visible .anchor {
    border-radius: 3px 3px 0 0;
}

.dropdown-check-list.visible .items {
    display: block;
    border-radius: 0 0 3px 3px;
}


.dropdown-check-list input {
    margin-left: 5px;
    margin-right: 7px;
    width: 15px;
    height: 15px;
}
