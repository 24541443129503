
body {
    font-weight: 500;
}


.link {
    text-decoration: underline;
    color: blue;
    font-weight: 600;
}